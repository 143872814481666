import { Language, useTranslation } from '@/components/I18N';
import { TenantSubtypeCategory } from '@/graphql-types';
import { Container, Text, UnstyledButton, Stack, Image, Group, TextInput, Button } from '@mantine/core';
import cx from 'classnames';
import { useFormikContext } from 'formik';
import { FormStep1Values } from '../../types';
import { useState } from 'react';
import { OTHER_SUBTYPE_PARENT_ID, OTHER_SUBTYPE_LOCALIZED_ID } from '@/utils';
import cloneDeep from 'lodash/cloneDeep';

type TenantSubtypesListProps = {
  subTypesList: TenantSubtypeCategory[];
  closeSelfEmplModal: () => void;
};

const TenantSubtypesList = ({ subTypesList, closeSelfEmplModal }: TenantSubtypesListProps) => {
  const { i18n } = useTranslation();

  let language;

  if (i18n.language === Language.EN) {
    language = Language.EN;
  } else if (i18n.language === Language.PT) {
    language = Language.PT;
  } else if (i18n.language === Language.ES) {
    language = Language.ES;
  } else if (i18n.language === Language.IT) {
    language = Language.IT;
  } else {
    language = Language.PT;
  }

  const { setFieldValue, values, setFieldError, errors } = useFormikContext<FormStep1Values>();
  const [otherSubtypeFreeInputValue, setOtherSubtypeFreeInputValue] = useState(values.subtypeFreeInput || '');
  const langForFilter = [Language.EN, Language.PT].includes(language) ? language : Language.EN;
  const localizedSubTypes = cloneDeep(
    subTypesList
      .map((subType) => ({
        ...subType,
        localizations: subType.localizations.find((localization) => localization.locale === langForFilter),
      }))
      .filter((subType) => subType.localizations)
  );

  // TODO: this is a temporary solution until we migrate content to strapi: we use i18n for ES and IT
  if ([Language.ES, Language.IT].includes(language)) {
    localizedSubTypes.forEach((subType) => {
      if (subType.localizations) {
        subType.localizations.name = i18n.t(`hygraph.activityType.${subType.id}`);
      }

      if (subType.localizations?.tenantSubtypes) {
        subType.localizations.tenantSubtypes.forEach((tenantSubType) => {
          tenantSubType.name = i18n.t(`hygraph.activityType.${tenantSubType.id}`);
        });
      }
    });
  }

  return (
    <Container className="pb-12 pt-5 sm:pb-0">
      {localizedSubTypes.map((subType) => {
        const isOtherSubtype = subType.id === OTHER_SUBTYPE_PARENT_ID;

        return (
          <div key={subType.id} className="mb-6 last:mb-0">
            {!isOtherSubtype ? (
              <>
                <Text size="xl" className="mb-4 font-semibold">
                  {subType?.localizations?.name || ''}
                </Text>

                <Stack>
                  {subType?.localizations?.tenantSubtypes.map((tenantSubType) => {
                    return (
                      <UnstyledButton
                        key={tenantSubType.id}
                        className={cx(
                          "hover:outline-moroccan-blue' rounded-[24px] p-4 text-base outline outline-gray88 hover:outline-2",
                          {
                            'outline-moroccan-blue': values.subtype === tenantSubType.id,
                          }
                        )}
                        onClick={() => {
                          setFieldValue('subtype', tenantSubType.id);
                          setFieldValue('subtypeFreeInput', '');
                          closeSelfEmplModal();
                        }}
                      >
                        <div className="flex gap-3">
                          <Image src={tenantSubType.icon || ''} className="my-auto size-[23px]" />
                          <span className="font-normal">{tenantSubType.name}</span>
                        </div>
                      </UnstyledButton>
                    );
                  })}
                </Stack>
              </>
            ) : (
              <div className="w-full">
                <Text size="xl" className="mb-4 font-semibold">
                  {subType?.localizations?.name || ''}
                </Text>
                <Group justify="space-between">
                  <TextInput
                    name="subtypeFreeInput"
                    className="flex-1"
                    size="fiz-lg"
                    classNames={{
                      input: 'p-4',
                    }}
                    value={otherSubtypeFreeInputValue}
                    onChange={(event) => {
                      if (errors.subtypeFreeInput) setFieldError('subtypeFreeInput', '');

                      setOtherSubtypeFreeInputValue(event.currentTarget.value);
                    }}
                  />

                  <Button
                    size="fiz-lg"
                    className="rounded-3xl px-10 py-5"
                    onClick={() => {
                      if (otherSubtypeFreeInputValue.length < 1) {
                        setFieldError('subtypeFreeInput', i18n.t('validation.minLength', { count: 3 }));
                        return;
                      }
                      if (otherSubtypeFreeInputValue.length > 255) {
                        setFieldError('subtypeFreeInput', i18n.t('validation.maxLength', { count: 255 }));
                        return;
                      }

                      setFieldValue('subtype', OTHER_SUBTYPE_LOCALIZED_ID);
                      setFieldValue('subtypeFreeInput', otherSubtypeFreeInputValue);
                      closeSelfEmplModal();
                    }}
                    disabled={!otherSubtypeFreeInputValue || !otherSubtypeFreeInputValue.replace(/\s/g, '').length}
                  >
                    {i18n.t('button.label.ok')}
                  </Button>
                </Group>
                {errors.subtypeFreeInput && (
                  <div id="type" className="mt-2 text-sm text-sunset-orange">
                    {i18n.t(errors.subtypeFreeInput)}
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </Container>
  );
};

export default TenantSubtypesList;
