import { Tenant_Type } from '@/graphql-types';
import { COMPANY_NAME_MAX_LENGTH, PT_POSTAL_CODE_REGEX } from '@/utils';
import { nifPattern, validateNifControlDigit } from '@/utils/nifNipc';
import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const step2ValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    country: Yup.string().required(t('validation.required')),
    type: Yup.string().required(t('validation.typeRequired')),
    subtype: Yup.string().when('type', {
      is: (type: string) => type === Tenant_Type.Individual,
      then: () => Yup.string().required(t('validation.subTypeRequired')),
    }),
    taxPayerNumber: Yup.string().when('type', {
      is: (type: string) => type === Tenant_Type.Individual,
      then: () =>
        Yup.string()
          .required(t('validation.nifRequired'))
          .matches(nifPattern, t('validation.invalidNifFormat'))
          .test('nif-control-digit', validateNifControlDigit),
      otherwise: () =>
        Yup.string()
          .required(t('validation.nipcRequired'))
          .matches(nifPattern, t('validation.invalidNifFormat'))
          .test('nif-control-digit', validateNifControlDigit),
    }),
    name: Yup.string()
      .trim()
      .required(t('validation.nameRequired'))
      .max(COMPANY_NAME_MAX_LENGTH, t('validation.nameLimitationsSymbols', { length: COMPANY_NAME_MAX_LENGTH })),
    address: Yup.string()
      .trim()
      .required(t('validation.addressRequired'))
      .max(210, t('validation.addressLimitationsSymbols')),
    city: Yup.string().trim().required(t('validation.cityRequired')).max(50, t('validation.cityLimitationsSymbols')),
    territory: Yup.string().required(t('validation.territoryRequired')),
    postalCode: Yup.string()
      .required(t('validation.postalCodeRequired'))
      .matches(PT_POSTAL_CODE_REGEX, t('validation.postalCodeFormat')),
  });
