import { showErrorToast } from '@/components/Toast';
import {
  useGetTenantSubtypeCategoriesLazyQuery,
  useBusinessDataByTaxInfoQuery,
  useFeatureFlagsQuery,
} from '@/graphql-operations';
import { useTranslation } from '@/components/I18N';

const useStep1Queries = () => {
  const { t } = useTranslation();

  const [fetchSubtypeCategories, { data: subtypesData, loading: getSubtypeCategoriesLoading }] =
    useGetTenantSubtypeCategoriesLazyQuery({
      onError: (error) => {
        showErrorToast({ title: 'Error', message: error.message });
      },
    });

  const { refetch: businessDataByNifRefetch, loading: businessDataByNifLoading } = useBusinessDataByTaxInfoQuery({
    skip: true,
    onError: (error) => {
      showErrorToast({ title: t('error.getInfoByNif'), message: error.message });
      return;
    },
  });

  const { data: ff, loading: featureFlagsLoading } = useFeatureFlagsQuery();

  return {
    ff,
    fetchSubtypeCategories,
    subtypesData,
    businessDataByNifRefetch,
    areQueriesLoading: getSubtypeCategoriesLoading || businessDataByNifLoading || featureFlagsLoading,
  };
};

export default useStep1Queries;
