import { showErrorToast } from '@/components/Toast';
import { useOnboardingConfirmTenantMutation, useUpdateTenantMutation } from '@/graphql/operations';

const useStep2Mutations = () => {
  const [confirmTenantMutation, { loading: confirmTenantLoading }] = useOnboardingConfirmTenantMutation({
    onError: (error) => {
      showErrorToast({ title: 'Error', message: error.message });
      return;
    },
  });

  const [updateTenantMutation, { loading: updateTenantLoading }] = useUpdateTenantMutation({
    onError: (error) => {
      showErrorToast({ title: 'Error', message: error.message });
      return;
    },
  });

  return {
    confirmTenantMutation,
    updateTenantMutation,
    areMutationsLoading: confirmTenantLoading || updateTenantLoading,
  };
};

export default useStep2Mutations;
