import { FeatureFlags, Tenant } from '@/graphql-types';
import { WAITLIST_ONB_STEP } from '@/utils';

export const isWaitlistedCountry = (countryCode: string, ff?: FeatureFlags) => {
  const waitlistedCountries: Record<string, boolean> = {
    PT: !!ff?.onboardingWaitlistPT,
    ES: !!ff?.onboardingWaitlistES,
    IT: !!ff?.onboardingWaitlistIT,
  };

  return waitlistedCountries[countryCode] ?? false;
};

const getShouldRedirectToOnboarding = (hasTenants: boolean, tenantObject?: Tenant, ff?: FeatureFlags) => {
  const onboardingWaitlist = tenantObject?.country ? isWaitlistedCountry(tenantObject.country, ff!) : false;
  const isOnboardingFinishedWhiteListStep = tenantObject?.onboarding?.currentStep === WAITLIST_ONB_STEP;
  const shouldRedirectToWhiteListPage = onboardingWaitlist && isOnboardingFinishedWhiteListStep;

  const onboardingIsNotFinished = !tenantObject?.onboarding?.finished && tenantObject?.isInitial;
  const noTenant = !tenantObject?.id;
  const noTenantsInDb = !hasTenants;

  const shouldRedirectToGetStartedPage = [
    shouldRedirectToWhiteListPage,
    onboardingIsNotFinished,
    noTenant,
    noTenantsInDb,
  ].includes(true);

  return shouldRedirectToGetStartedPage;
};

export default getShouldRedirectToOnboarding;
