import { getData } from 'iso-3166-1-alpha-2';
import { Stack, UnstyledButton, UnstyledButtonProps } from '@mantine/core';
import FlagPtIcon from '@/assets/flags/btn/pt.svg?react';
import FlagEsIcon from '@/assets/flags/btn/es.svg?react';
import FlagItIcon from '@/assets/flags/btn/it.svg?react';
// import FlagBrIcon from '@/assets/flags/btn/br.svg?react';
import cn from 'classnames';
import { useField, useFormikContext } from 'formik';
import { OnboardingCountry } from '@/utils';
import { useTranslation } from 'react-i18next';

const countryData = getData();

const flags = {
  PT: <FlagPtIcon className="m-auto" />,
  ES: <FlagEsIcon className="m-auto" />,
  IT: <FlagItIcon className="m-auto" />,
  // BR: <FlagBrIcon className="m-auto" />,
};

export type CountryButtonProps = UnstyledButtonProps & {
  country: OnboardingCountry;
  selected: boolean;
  onClick: () => void;
};

export const CountryButton = (props: CountryButtonProps) => {
  const { t } = useTranslation();
  const { country, selected, ...rest } = props;

  return (
    <UnstyledButton
      className={cn(
        'rounded-[20px] py-4 outline',
        {
          'outline-2 outline-moroccan-blue': selected,
        },
        {
          'outline-1 outline-gray88': !selected,
        }
      )}
      {...rest}
    >
      <Stack gap={5}>
        {flags[country]}
        <div
          className={cn('text-center text-sm text-light-black-magic', {
            'font-semibold': selected,
            'font-normal': !selected,
          })}
        >
          {t(`country.${countryData[country]}`)}
        </div>
      </Stack>
    </UnstyledButton>
  );
};

export type CountryRadioButtonsProps = {
  name: string;
  countries: OnboardingCountry[];
  defaultCountry: string;
  onSelectCountry: (country: OnboardingCountry) => void;
};

export const CountryRadioButtons = (props: CountryRadioButtonsProps) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props.name);

  return (
    <div className="grid grid-cols-3 gap-5 sm:grid-cols-3">
      {props.countries.map((country) => (
        <CountryButton
          key={country}
          country={country}
          selected={field.value === country}
          onClick={async () => {
            await setFieldValue(props.name, country);
            props.onSelectCountry(country);
          }}
        />
      ))}
    </div>
  );
};
