import { Button, Stack, Text } from '@mantine/core';
// import { useUpdateTenantMutation } from '@/graphql-operations';
import { useTranslation } from '@/components/I18N';
// import { showErrorToast } from '@/components/Toast';
import { tenantVar } from '@/config/reactiveVars/tenantVar';
import { useReactiveVar } from '@apollo/client';
import RocketIcon from '@/assets/rocket.svg?react';
// import { WAITLIST_ONB_STEP, redirectOnOnboardingComplete } from '@/utils';
import { Link } from 'react-router-dom';

const Step3Form = () => {
  const { t } = useTranslation();
  const tenantObject = useReactiveVar(tenantVar);

  // const [updateTenantMutation, { loading: updateTenantMutationLoading }] = useUpdateTenantMutation({
  //   onCompleted: (data) => {
  //     if (data?.updateTenant) {
  //       tenantVar(data?.updateTenant);

  //       return redirectOnOnboardingComplete();
  //     }
  //   },
  //   onError: (error) => {
  //     showErrorToast({ title: 'Error:', message: error.message });
  //     return;
  //   },
  // });

  if (!tenantObject?.id) {
    return <p>{t('error.tenantNotFound')}</p>;
  }

  // const handleUpdateTenant = async () => {
  //   if (tenantObject?.onboarding?.currentStep === WAITLIST_ONB_STEP && tenantObject?.onboarding?.finished) {
  //     return redirectOnOnboardingComplete();
  //   }

  //   const updatedTenant = await updateTenantMutation({
  //     variables: {
  //       updateTenantId: tenantObject.id,
  //       tenant: {
  //         language: getLanguageFromLocalStorage(),
  //       },
  //     },
  //   });

  //   const tenant = updatedTenant.data?.updateTenant;

  //   if (!tenant) {
  //     console.error(updatedTenant.errors);
  //     return;
  //   }

  //   tenantVar(tenant);
  // };

  return (
    <div className="flex h-[42vh] flex-col items-center">
      <Stack align="center" justify="center" className="w-[360px]">
        <RocketIcon />
        <Text className="text-[32px] font-medium">{t('page.onboarding.step3.header')}</Text>
        <Text className="text-center text-sm">{t('page.onboarding.step3.subheader')}</Text>
        <Button
          className="mt-4 rounded-[24px] bg-fiz-4 hover:bg-fiz-5"
          component={Link}
          to={`https://fiz.co/${tenantObject.language?.toLowerCase()}/${tenantObject.country?.toLowerCase()}/invoicing`}
          // loading={updateTenantMutationLoading}
          loaderProps={{ type: 'dots' }}
          fullWidth
          size="xl"
          // onClick={handleUpdateTenant}
        >
          {t('button.label.backToHome')}
        </Button>
      </Stack>
    </div>
  );
};

export default Step3Form;
