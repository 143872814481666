import { showErrorToast } from '@/components/Toast';
import { useOnboardingJoinWaitlistMutation, useOnboardingPrepareTenantMutation } from '@/graphql-operations';

const useStep1Mutations = () => {
  const [prepareTenantMutation, { loading: loadingPrepareTenant }] = useOnboardingPrepareTenantMutation({
    onError: (error) => {
      showErrorToast({ title: 'Error', message: error.message });
      return;
    },
  });

  const [joinWaitlistMutatuon, { loading: loadingJoinWaitlist }] = useOnboardingJoinWaitlistMutation({
    onError: (error) => {
      showErrorToast({ title: 'Error', message: error.message });
      return;
    },
  });

  return {
    prepareTenantMutation,
    joinWaitlistMutatuon,
    areMutationsLoading: loadingPrepareTenant || loadingJoinWaitlist,
  };
};

export default useStep1Mutations;
