import { useFormikContext } from 'formik';
import { LazyQueryExecFunction } from '@apollo/client';
import { Group, Text } from '@mantine/core';
import cx from 'classnames';
import { IMaskInput } from 'react-imask';
import { Exact } from '@/graphql-types';
import { AddressDataByPostalCodeQuery } from '@/graphql/operations';
import { FormikInput } from '@/components/FormikWrapped';
import { FormStep2Values } from '../../types';

type EditableFieldProps = {
  fieldData: {
    name: string;
    label: string;
    placeholder: string;
    isShown: boolean;
    isEditable: boolean;
  };
  fetchAddressByPostCode: LazyQueryExecFunction<
    AddressDataByPostalCodeQuery,
    Exact<{
      country: string;
      postalCode: string;
    }>
  >;
};

const EditableField = (propsBag: EditableFieldProps) => {
  const { values, errors, setFieldValue, setFieldError } = useFormikContext<FormStep2Values>();
  const { fieldData } = propsBag;
  const { name, label, placeholder, isShown, isEditable } = fieldData;

  const fieldsWithBorder = ['taxPayerNumber', 'cae', 'country', 'type', 'subtype'];

  const formikValue = values[name as keyof FormStep2Values];

  if (!isShown) {
    return null;
  }

  const getFields = () => {
    // read only fields
    if (!isEditable) {
      return (
        <div className="w-full py-2 pt-2 group-first-of-type:pt-0 group-last-of-type:pb-0 sm:flex sm:items-center sm:justify-between sm:py-4 sm:pt-4 group-first-of-type:sm:pt-0">
          <Text className="text-[11px] font-semibold text-schooner sm:text-lg">{label}</Text>
          <Text className="break-word text-[17px] text-black-magic sm:text-right sm:text-lg">
            {Array.isArray(formikValue) ? formikValue.join(', ') : formikValue}
          </Text>
        </div>
      );
    }

    if (['address', 'city', 'name'].includes(name) && isShown) {
      return (
        <FormikInput
          className="my-1.5 w-full"
          name={name}
          label={label}
          placeholder={placeholder}
          onChange={(event) => {
            if (errors[name as keyof FormStep2Values]) {
              setFieldError(name, '');
            }

            setFieldValue(name, event.currentTarget.value);
          }}
          // onBlur={() => validateField(name)}
          customClassNames={{
            input: 'focus:border-moroccan-blue',
          }}
          error={errors[name as keyof FormStep2Values]}
        />
      );
    }

    if (name === 'postalCode') {
      return (
        <FormikInput
          name="postalCode"
          className="my-1 w-full"
          customClassNames={{
            input: 'focus:border-moroccan-blue',
          }}
          component={IMaskInput}
          mask="0000-000"
          handleOnAccept={(value) => {
            if (errors.postalCode) {
              setFieldError('postalCode', '');
            }
            setFieldValue('postalCode', value);
          }}
          // onBlur={async (e) => {
          //   handleBlur(e);
          //   await validateField('postalCode');

          //   if (values.postalCode?.match(PT_POSTAL_CODE_REGEX) === null) {
          //     return;
          //   }

          //   // Moved to backend
          //   return;

          //   // const { data } = await fetchAddressByPostCode({
          //   //   variables: {
          //   //     country: DEFAULT_COUNTRY,
          //   //     postalCode: values.postalCode!,
          //   //   },
          //   // });

          //   // if (data?.addressDataByPostalCode.city) {
          //   //   setFieldValue('city', data.addressDataByPostalCode.city);
          //   // }

          //   // if (data?.addressDataByPostalCode.vat_region) {
          //   //   const vatRegionToSet =
          //   //     data?.addressDataByPostalCode.vat_region === 'UNKNOWN'
          //   //       ? Tenant_Pt_Territory.Continental
          //   //       : data?.addressDataByPostalCode.vat_region;
          //   //   setFieldValue('territory', vatRegionToSet);
          //   // }

          //   // setFieldValue('address', DEFAULT_ADDRESS);
          // }}
          label={label}
          placeholder={label}
          error={errors.postalCode ? errors.postalCode : null}
          inputMode="numeric"
        />
      );
    }

    // if (name === 'territory' && isShown) {
    //   return (
    //     <FormikSelect
    //       className="my-1.5 w-full"
    //       customClassNames={{
    //         input: 'focus:border-moroccan-blue',
    //       }}
    //       name={name}
    //       label={label}
    //       placeholder={placeholder}
    //       onBlur={() => validateField('territory')}
    //       data={[
    //         { value: Tenant_Pt_Territory.Azores, label: t('label.azores') },
    //         { value: Tenant_Pt_Territory.Continental, label: t('label.mainland') },
    //         { value: Tenant_Pt_Territory.Madeira, label: t('label.madeira') },
    //       ]}
    //       error={errors.territory ? errors.territory : null}
    //     />
    //   );
    // }
  };

  return (
    <Group className={cx('group last:border-b-0', { 'border-b border-alice-gray': fieldsWithBorder.includes(name) })}>
      {getFields()}
    </Group>
  );
};

export default EditableField;
