import { Formik, Form } from 'formik';
import { useReactiveVar } from '@apollo/client';
import { Flex, Stack, Text } from '@mantine/core';
import { IconChevronLeft } from '@tabler/icons-react';
import { useAddressDataByPostalCodeLazyQuery, useFeatureFlagsQuery } from '@/graphql-operations';
import { getLanguageFromLocalStorage, useTranslation } from '@/components/I18N';
import { tenantVar } from '@/config/reactiveVars/tenantVar';
import { FormStep2Values } from '../types';
import EditableField from './parts/EditableField';
import { step2ValidationSchema } from './validationSchema';
import { Tenant_Type } from '@/graphql-types';
import { getData } from 'iso-3166-1-alpha-2';
import { OTHER_SUBTYPE_LOCALIZED_ID, WAITLIST_ONB_STEP } from '@/utils';
import { useState } from 'react';
import { pushToGTMDataLayer, READ_ONLY_GTM_EVENTS, READ_ONLY_GTM_EVENT_TYPES } from '@/utils/manageGTM';
import { scrollToErrors } from '@/utils/scrollToErrors';
import { getFieldDataArray } from './helpers';
import useStep2Mutations from './useStep2Mutations';
import { useMediaQuery } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
import { isWaitlistedCountry } from '@/utils/getShouldRedirectToOnboarding';
import { Button } from '@/components/ButtonVariants';

// FIXME: Uzhas we have to rethink this!
const countryData = getData();

const Step2Form = () => {
  const [isFirstSubmitAttempt, setIsFirstSubmitAttempt] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isSmScreen = useMediaQuery('(max-width: 575px)');
  const currentLanguage = getLanguageFromLocalStorage();
  const tenantObject = useReactiveVar(tenantVar);
  const [loadingBack, setLoadingBack] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const { confirmTenantMutation, updateTenantMutation, areMutationsLoading } = useStep2Mutations();

  const [fetchAddressByPostCode, { loading: addressByPostCodeLoading }] = useAddressDataByPostalCodeLazyQuery();

  const { data: ff, loading: featureFlagsLoading } = useFeatureFlagsQuery();
  const isWaitlistedCountrySelected = isWaitlistedCountry(tenantObject?.country as string, ff?.featureFlags);

  if (!tenantObject?.id) {
    return <p>{t('error.tenantNotFound')}</p>;
  }

  const handleBack = async () => {
    setLoadingBack(true);

    const updatedTalent = await updateTenantMutation({
      variables: {
        updateTenantId: tenantObject.id,
        tenant: {
          language: currentLanguage,
          onboarding: {
            currentStep: 0,
            finished: false,
          },
        },
      },
    });

    tenantVar(updatedTalent.data?.updateTenant);
    navigate(-1);
  };

  const getTypeOfActivity = () => {
    if (tenantObject.type === Tenant_Type.Company) {
      return t('label.company');
    }

    const localizedSubtype = tenantObject.subtypeData?.localizations.find(
      (localization) => localization.locale === getLanguageFromLocalStorage()
    )?.name;

    if (tenantObject.subtypeData?.id === OTHER_SUBTYPE_LOCALIZED_ID) {
      return tenantObject.subtypeFreeInput;
    }

    return localizedSubtype;
  };

  return (
    <>
      <Formik<FormStep2Values>
        initialValues={{
          type: getTypeOfActivity(),
          country: countryData[tenantObject.country as string],
          taxPayerNumber: tenantObject.taxPayerNumber,
          name: tenantObject.name,
          address: tenantObject.address,
          territory: tenantObject.territory,
          subtype: tenantObject.subtype || '',
          postalCode: tenantObject.postalCode || '',
          city: tenantObject.city || '',
          cae: tenantObject.cae || [],
          // incomeLessThan: false,
        }}
        validationSchema={step2ValidationSchema(t)}
        validateOnBlur={isFirstSubmitAttempt}
        validateOnChange={isFirstSubmitAttempt}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          setLoadingNext(true);

          const updatedTenant = await confirmTenantMutation({
            variables: {
              id: tenantObject.id,
              tenant: {
                name: values?.name?.trim(),
                postalCode: values.postalCode,
                acceptedTosAndPrivacyPolicy: true,
                language: getLanguageFromLocalStorage(),
                onboarding: {
                  currentStep: isWaitlistedCountrySelected ? WAITLIST_ONB_STEP : 1,
                  finished: true,
                },
              },
            },
          });

          const tenant = updatedTenant.data?.onboardingConfirmTenant;

          if (!tenant) {
            console.error(updatedTenant.errors);
            return;
          }

          pushToGTMDataLayer(READ_ONLY_GTM_EVENTS.SEND_FORM_SUCCESS, READ_ONLY_GTM_EVENT_TYPES.REG_SUCCESS);

          tenantVar(tenant);

          navigate('/invoices');
          setSubmitting(false);
        }}
      >
        {({ errors, handleSubmit, isSubmitting }) => {
          const isFormDisabled = isSubmitting || areMutationsLoading || addressByPostCodeLoading;
          return (
            <Form className="w-full px-2 sm:mx-0 sm:max-w-[480px] sm:px-0">
              <Stack className="mt-4" gap="lg">
                <Text
                  style={!isSmScreen ? { fontFamily: 'Artex, sans-serif' } : undefined}
                  className="text-center text-[32px] font-semibold"
                >
                  {t('label.confirmation')}
                </Text>
                <div className="rounded-[24px] bg-white p-5">
                  {getFieldDataArray(t, tenantObject).map(
                    (fieldData) =>
                      fieldData &&
                      fieldData.name && (
                        <EditableField
                          key={fieldData.name}
                          fieldData={fieldData}
                          fetchAddressByPostCode={fetchAddressByPostCode}
                        />
                      )
                  )}
                </div>
              </Stack>
              <Flex justify="space-around" className="sticky bottom-0 z-10" mt={20} gap={6}>
                <Button
                  variant="secondary"
                  className="mb-5 rounded-[24px]"
                  leftSection={<IconChevronLeft />}
                  loading={loadingBack}
                  disabled={isFormDisabled}
                  size="l"
                  fullWidth
                  onClick={handleBack}
                >
                  {t('button.label.back')}
                </Button>
                <Button
                  className="mb-5 rounded-[24px]"
                  loading={loadingNext}
                  disabled={isFormDisabled || featureFlagsLoading}
                  onClick={() => {
                    setIsFirstSubmitAttempt(true);
                    scrollToErrors(errors);
                    handleSubmit();
                  }}
                  size="l"
                  fullWidth
                  variant="primary"
                >
                  {t('button.label.confirm')}
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default Step2Form;
