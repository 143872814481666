import { Text, UnstyledButton } from '@mantine/core';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import IconRadioButtonOff from '@/assets/icons/IconRadioButtonOff.svg?react';
import IconRadioButtonOn from '@/assets/icons/IconRadioButtonOn.svg?react';
import { Button } from '@/components/ButtonVariants';
import { Tenant_Type } from '@/graphql-types';

import { FormStep1Values } from '../../types';

type BusinessTypeButtonProps = {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  type: Tenant_Type;
  businessTypeTitle: string;
  businessTypeSubtitle?: string;
  handleChangeClick?: () => void;
  nifInputRef?: React.RefObject<{ element: HTMLInputElement }>;
};

const BusinessTypeButton = ({
  icon: BusinessTypeIcon,
  type,
  businessTypeTitle,
  businessTypeSubtitle,
  handleChangeClick,
  nifInputRef,
}: BusinessTypeButtonProps) => {
  const { t } = useTranslation();
  const { values, setFieldValue, validateField, setFieldError } = useFormikContext<FormStep1Values>();
  const isTypeSelected = values.type === type;
  const isSelfEmplType = type === Tenant_Type.Individual;

  return (
    <UnstyledButton
      className={cn(
        'container mx-auto min-h-[90px] rounded-3xl outline',
        {
          'outline-2 outline-moroccan-blue': isTypeSelected,
        },
        {
          'outline-1 outline-gray88': !isTypeSelected,
        }
      )}
      onBlur={() => {
        validateField('type');
      }}
      onClick={async () => {
        setFieldValue('type', type);
        setFieldError('type', '');

        // await next tick to ensure that nifInputRef is set
        await new Promise((resolve) => setTimeout(resolve, 0));

        // Focus on NIF input
        if (nifInputRef?.current?.element) {
          nifInputRef.current.element.scrollIntoView();
          nifInputRef.current.element.focus();
        }

        // Reset subtype if it's not self-employed type
        if (!isSelfEmplType && values.subtype) {
          setFieldValue('subtype', '');
        }

        // Open modal if it's self-employed type and subtype is not set
        if (isSelfEmplType && !values.subtype) {
          if (handleChangeClick) {
            handleChangeClick();
          }
        }

        if (!isTypeSelected) {
          setFieldValue('NIF', '');
          setFieldError('NIF', '');
        }
      }}
    >
      <div className="flex items-center justify-between gap-2 bg-white px-5 py-4">
        <div className="flex gap-2">
          <UnstyledButton onClick={() => setFieldValue('isTypeSelected', isTypeSelected)}>
            {isTypeSelected ? <IconRadioButtonOn className="fill-moroccan-blue" /> : <IconRadioButtonOff />}
          </UnstyledButton>
          <div className="flex items-center justify-center">
            <BusinessTypeIcon
              className={cn(
                'flex-shrink-0',
                {
                  'fill-moroccan-blue': isTypeSelected,
                },
                {
                  'fill-black-magic': !isTypeSelected,
                }
              )}
            />
          </div>
          <div className="flex flex-shrink flex-grow flex-col">
            <div className="break-words font-bold">
              <Text
                className={cn(
                  {
                    'text-moroccan-blue': isTypeSelected,
                  },
                  {
                    'text-black-magic': !isTypeSelected,
                  }
                )}
              >
                {businessTypeTitle}
              </Text>
            </div>
            <div className="break-words text-sm text-gray-600">
              {businessTypeSubtitle && (
                <Text
                  className={cn(
                    'text-[11px]',
                    {
                      'text-moroccan-blue': isTypeSelected,
                    },
                    {
                      'text-schooner': !isTypeSelected,
                    }
                  )}
                >
                  {businessTypeSubtitle}
                </Text>
              )}
            </div>
          </div>
        </div>
        {values.subtype && isSelfEmplType && (
          <Button
            variant="secondary"
            size="xs"
            className="ml-auto h-[32px] flex-shrink-0 bg-[#7784B514]"
            onClick={handleChangeClick}
          >
            {t('button.label.change')}
          </Button>
        )}
      </div>
    </UnstyledButton>
  );
};

export default BusinessTypeButton;
