import { Tenant, Tenant_Type } from '@/graphql-types';
import { PT_POSTAL_CODE_REGEX } from '@/utils';
import { TFunction } from 'i18next';

const getFieldOrder = (isCompany: boolean, isFound: boolean) => {
  let fields;

  if (isCompany) {
    fields = ['taxPayerNumber', 'cae', 'name', 'address', 'city', 'postalCode', 'territory'];
  } else {
    fields = ['taxPayerNumber', 'type', 'subtype', 'name', 'address', 'city', 'postalCode', 'territory'];
  }

  if (!isFound) {
    fields = ['taxPayerNumber', 'name', 'postalCode', 'city', 'address', 'territory'];

    if (isCompany) {
      fields.splice(1, 0, 'cae');
    } else {
      fields.splice(1, 0, 'type', 'subtype');
    }
  }

  return fields;
};

export const getFieldDataArray = (t: TFunction, tenantObject: Tenant) => {
  const isCompanyType = tenantObject.type === Tenant_Type.Company;
  const isAddressDetailsFoundByNif = !!tenantObject.postalCode;
  const fieldOrder = getFieldOrder(isCompanyType, isAddressDetailsFoundByNif);

  return fieldOrder.map((field) => {
    const value = tenantObject?.[field as keyof typeof tenantObject];

    let fieldHasValueInTenantObject = !!value;

    if (Array.isArray(value)) {
      fieldHasValueInTenantObject = value.length > 0;
    }

    switch (field) {
      case 'taxPayerNumber':
        return {
          name: field,
          label: t(isCompanyType ? 'label.companyNipc' : 'label.nifMain'),
          placeholder: '',
          isShown: true,
          isEditable: false,
        };
      case 'type':
        return {
          name: field,
          label: t('label.typeOfActivity'),
          placeholder: '',
          isShown: true,
          isEditable: false,
        };
      case 'subtype':
        return {
          name: field,
          label: t('label.typeOfActivity'),
          placeholder: '',
          isShown: false,
          isEditable: false,
        };

      case 'cae':
        return {
          name: field,
          label: t('label.activityCodes'),
          placeholder: '',
          isShown: fieldHasValueInTenantObject,
          isEditable: false,
        };

      case 'name':
        return {
          name: field,
          label: t(`label.${field}`),
          placeholder: t(`label.${field}`),
          isShown: true,
          isEditable: !fieldHasValueInTenantObject,
        };

      case 'postalCode':
        return {
          name: field,
          label: t(`label.${field}`),
          placeholder: t(`label.${field}`),
          isShown: !fieldHasValueInTenantObject || !PT_POSTAL_CODE_REGEX.test(value as string),
          isEditable: !fieldHasValueInTenantObject || !PT_POSTAL_CODE_REGEX.test(value as string),
        };

      case 'city':
        return {
          name: field,
          label: t(`label.${field}`),
          placeholder: t(`label.${field}`),
          isShown: false,
          isEditable: false,
        };

      case 'address':
        return {
          name: field,
          label: t(`label.${field}`),
          placeholder: t(`label.${field}`),
          isShown: false,
          isEditable: false,
        };

      case 'territory':
        return {
          name: field,
          label: t(`label.${field}`),
          placeholder: t(`label.${field}`),
          isShown: false,
          isEditable: false,
        };
    }
  });
};
