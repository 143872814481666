import React, { useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { IMaskInput } from 'react-imask';
import { useReactiveVar } from '@apollo/client';
import { Flex, Text, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Maybe, Tenant, Tenant_Type, TenantSubtypeCategory } from '@/graphql-types';
import { Language, TFunction, getLanguageFromLocalStorage, useTranslation } from '@/components/I18N';
import { DEFAULT_COUNTRY, ONBOARDING_COUNTRIES, OTHER_SUBTYPE_LOCALIZED_ID, WAITLIST_ONB_STEP } from '@/utils';
import { pushToGTMDataLayer, READ_ONLY_GTM_EVENTS, READ_ONLY_GTM_EVENT_TYPES } from '@/utils/manageGTM';
import { isSupportedNIFNIPC } from '@/utils/nifNipc';
import { scrollToErrors } from '@/utils/scrollToErrors';
import { CURRENT_TENANT_STORAGE_KEY } from '@/config/constants';
import { tenantVar } from '@/config/reactiveVars/tenantVar';
import { CountryRadioButtons } from '@/components/IconCountries/CountryButton';
import { FormikInput } from '@/components/FormikWrapped';
import SelfEmplIcon from '@/assets/self-empl.svg?react';
import BriefcaseIcon from '@/assets/briefcase.svg?react';
import { FormStep1Values } from '../types';
import { availableCountrySchema, waitlistedCountrySchema } from './validationSchema';
import BusinessTypeButton from './parts/BusinessTypeButton';
import SelfEmployedModal from './parts/SelfEmployedModal';
import useStep1Mutations from './useStep1Mutations';
import useStep1Queries from './useStep1Queries';
import { useNavigate } from 'react-router-dom';
import { getPathPrefixByCountry, useCountryFromParams } from '@/hooks/useCountryFromParams';
import { isWaitlistedCountry } from '@/utils/getShouldRedirectToOnboarding';
import { GraphQLFormattedError } from 'graphql';
import { Button } from '@/components/ButtonVariants';

const findTenantSubtypeName = (
  subTypes: TenantSubtypeCategory[],
  locale: Language,
  tenantSubtypeId: string,
  t: TFunction
) => {
  if (!subTypes) return '';

  const langForFilter = [Language.EN, Language.PT].includes(locale) ? locale : Language.EN;
  const selectedSubtype =
    subTypes
      .map((subType) => subType.localizations.find((localization) => localization.locale === langForFilter))
      .filter((localization) => localization)
      .flatMap((localization) => localization?.tenantSubtypes)
      .find((tenantSubtype) => tenantSubtype?.id === tenantSubtypeId) || null;

  if ([Language.ES, Language.IT].includes(locale)) {
    return t(`hygraph.activityType.${tenantSubtypeId}`);
  }
  return selectedSubtype?.name || '';
};

const Step1Form = () => {
  const nifInputRef = useRef<{ element: HTMLInputElement }>(null);
  const [isFirstSubmitAttempt, setIsFirstSubmitAttempt] = useState(false);

  const tenantObject = useReactiveVar(tenantVar);
  const { t } = useTranslation();
  const [isUnsupportedNifSubmitted, setIsUnsupportedNifSubmitted] = useState(false);
  const [selfEmplModalOpened, { close: closeSelfEmplModal, open: openSelfEmplModal }] = useDisclosure(false);

  const { prepareTenantMutation, joinWaitlistMutatuon, areMutationsLoading } = useStep1Mutations();
  const { fetchSubtypeCategories, subtypesData, ff, areQueriesLoading } = useStep1Queries();

  const { country } = useCountryFromParams();
  const navigate = useNavigate();

  const isDataLoading = areMutationsLoading || areQueriesLoading;

  const getSelfEmployedSubtitle = (subtypeId: string, subtypeFreeInput?: string) => {
    const language = getLanguageFromLocalStorage();
    const langForFilter = [Language.EN, Language.PT].includes(language) ? language : Language.EN;
    if (!subtypeId || !subtypesData) return t('placeholder.workerEntrepreneur');
    if (tenantObject?.subtypeData?.localizations.length && subtypeId && subtypeId === tenantObject?.subtype) {
      // If TenantObject has subtypeData and subtype is other and user has entered a custom subtype
      if (subtypeId === OTHER_SUBTYPE_LOCALIZED_ID && subtypeFreeInput) {
        return subtypeFreeInput;
      }

      let subTypeName = tenantObject?.subtypeData.localizations.find((sub) => sub.locale === langForFilter)?.name;

      if ([Language.ES, Language.IT].includes(language)) {
        subTypeName = t(`hygraph.activityType.${subtypeId}`);
      }

      return subTypeName;
    }

    // If subtype is other and user has entered a custom subtype
    if (subtypeId === OTHER_SUBTYPE_LOCALIZED_ID && subtypeFreeInput) {
      return subtypeFreeInput;
    }
    return findTenantSubtypeName(subtypesData.tenantSubtypeCategories, language, subtypeId, t);
  };

  useEffect(() => {
    if (nifInputRef.current?.element) {
      nifInputRef.current.element.scrollIntoView({ behavior: 'auto' });
      nifInputRef.current.element.scroll();
    }
  }, [nifInputRef.current?.element]);

  const isWaitlistedCountrySelected = isWaitlistedCountry(country, ff?.featureFlags);

  return (
    <Formik<FormStep1Values>
      initialValues={{
        country: tenantObject?.country || country,
        NIF: tenantObject?.taxPayerNumber || '',
        type: tenantObject?.type || undefined,
        subtype: tenantObject?.subtype || '',
        subtypeFreeInput: tenantObject?.subtypeFreeInput || '',
      }}
      enableReinitialize
      validationSchema={isWaitlistedCountrySelected ? waitlistedCountrySchema(t) : availableCountrySchema(t)}
      validateOnBlur={isFirstSubmitAttempt}
      validateOnChange={isFirstSubmitAttempt}
      onSubmit={async (values) => {
        if (country === 'PT' && !isSupportedNIFNIPC(values.NIF)) {
          setIsUnsupportedNifSubmitted(true);
          return;
        }

        let tenant: Tenant | undefined;
        let errors: readonly GraphQLFormattedError[] | undefined;

        if (isWaitlistedCountrySelected) {
          const joinWaitlistResponse = await joinWaitlistMutatuon({
            variables: {
              id: tenantObject?.id,
              tenant: {
                country,
                acceptedTosAndPrivacyPolicy: true,
                language: getLanguageFromLocalStorage(),
                type: values.type as Tenant_Type,
                subtype: values.subtype,
                subtypeFreeInput: values.subtypeFreeInput.trim(),
                onboarding: {
                  currentStep: WAITLIST_ONB_STEP,
                  finished: false,
                  waitlist: true,
                },
              },
            },
          });

          tenant = joinWaitlistResponse.data?.onboardingJoinWaitlist;
          errors = joinWaitlistResponse.errors;
        } else {
          const prepareTenantResponse = await prepareTenantMutation({
            variables: {
              id: tenantObject?.id,
              tenant: {
                type: values.type as Tenant_Type,
                country: country!,
                taxPayerNumber: values.NIF!,
                subtype: values.subtype,
                subtypeFreeInput: values.subtypeFreeInput.trim(),
                language: getLanguageFromLocalStorage(),
                onboarding: {
                  currentStep: 1,
                  finished: false,
                },
              },
            },
          });

          console.error(prepareTenantResponse.errors);

          tenant = prepareTenantResponse.data?.onboardingPrepareTenant;
          errors = prepareTenantResponse.errors;
        }

        if (!tenant) {
          console.error(errors);
          return;
        }

        if (!tenantObject?.id) {
          pushToGTMDataLayer(READ_ONLY_GTM_EVENTS.SEND_FORM_SUCCESS, READ_ONLY_GTM_EVENT_TYPES.REG_CHOOSE_USER_TYPE);
        }

        localStorage.setItem(CURRENT_TENANT_STORAGE_KEY, tenant?.id || '');
        tenantVar(tenant);

        if (isWaitlistedCountrySelected) {
          navigate('waitlist');
        } else {
          navigate('confirm');
        }
      }}
    >
      {({ errors, values, isSubmitting, handleSubmit, setFieldValue, validateForm, setFieldError }) => {
        const isSelectedBusinessTypeCompany = values.type === Tenant_Type.Company;
        const defaultCountryAndSupportedNif = !isWaitlistedCountrySelected && !isUnsupportedNifSubmitted;
        const selfEmployedSubtitle =
          values.type === Tenant_Type.Individual
            ? getSelfEmployedSubtitle(values.subtype, values.subtypeFreeInput)
            : t('placeholder.workerEntrepreneur');

        const getTitleForCountry = (country: Maybe<string>, t: TFunction) => {
          if (country === ONBOARDING_COUNTRIES.PT) {
            if (isSelectedBusinessTypeCompany) {
              return t('label.nipcMain');
            } else {
              return t('label.nifMain');
            }
          } else if (country === ONBOARDING_COUNTRIES.ES) {
            if (isSelectedBusinessTypeCompany) {
              return t('label.nipcMainEs');
            } else {
              return t('label.nifMainEs');
            }
          } else if (country === ONBOARDING_COUNTRIES.IT) {
            if (isSelectedBusinessTypeCompany) {
              return t('label.nifMainIt');
            } else {
              return t('label.nifMainIt');
            }
          } else {
            return null;
          }
        };

        const getLabelForCountry = (country: Maybe<string>, t: TFunction) => {
          if (country === ONBOARDING_COUNTRIES.PT) {
            if (isSelectedBusinessTypeCompany) {
              return t('label.nipc');
            } else {
              return t('label.nif');
            }
          } else if (country === ONBOARDING_COUNTRIES.ES) {
            if (isSelectedBusinessTypeCompany) {
              return t('label.nipcEs');
            } else {
              return t('label.nifEs');
            }
          } else if (country === ONBOARDING_COUNTRIES.IT) {
            if (isSelectedBusinessTypeCompany) {
              return t('label.nifIt');
            } else {
              return t('label.nifIt');
            }
          } else {
            return null;
          }
        };

        const getPlaceholderForCountry = (country: Maybe<string>, t: TFunction) => {
          if (country === ONBOARDING_COUNTRIES.PT) {
            if (isSelectedBusinessTypeCompany) {
              return t('label.nipc');
            } else {
              return t('label.nif');
            }
          } else if (country === ONBOARDING_COUNTRIES.ES) {
            if (isSelectedBusinessTypeCompany) {
              return t('placeholder.nipcEs');
            } else {
              return t('placeholder.nifEs');
            }
          } else if (country === ONBOARDING_COUNTRIES.IT) {
            if (isSelectedBusinessTypeCompany) {
              return t('placeholder.nifIt');
            } else {
              return t('placeholder.nifIt');
            }
          } else {
            return null;
          }
        };

        return (
          <Form className="w-full px-2 sm:mx-0 sm:max-w-[480px] sm:px-0">
            <Stack className="mt-4" gap="lg">
              <div className="w-full rounded-[24px] bg-white p-5">
                <Text className="pb-5 text-xl font-semibold text-black-magic">{t('label.selectCountry')}</Text>
                <CountryRadioButtons
                  name="country"
                  countries={Object.values(ONBOARDING_COUNTRIES)}
                  defaultCountry={DEFAULT_COUNTRY}
                  onSelectCountry={(selectedCountry) => {
                    localStorage.setItem('country', selectedCountry);
                    navigate(`${getPathPrefixByCountry(selectedCountry)}get-started`);
                  }}
                />
              </div>
              <div className="rounded-[24px] bg-white p-5">
                <Text className="pb-5 text-xl font-semibold text-black-magic">{t('label.typeOfActivity')}</Text>
                <Stack gap={12}>
                  <BusinessTypeButton
                    nifInputRef={nifInputRef}
                    icon={SelfEmplIcon}
                    type={Tenant_Type.Individual}
                    businessTypeTitle={t('label.selfEmployed')}
                    businessTypeSubtitle={selfEmployedSubtitle}
                    handleChangeClick={async () => {
                      await fetchSubtypeCategories();
                      openSelfEmplModal();
                    }}
                  />
                  <BusinessTypeButton
                    nifInputRef={nifInputRef}
                    icon={BriefcaseIcon}
                    type={Tenant_Type.Company}
                    businessTypeTitle={t('label.company')}
                    businessTypeSubtitle={t('page.onboarding.step1.companySubtitle')}
                  />
                </Stack>
                {errors.type && (
                  <div id="type" className="mt-2 text-sm text-sunset-orange">
                    {t(errors.type)}
                  </div>
                )}
              </div>
              {isWaitlistedCountrySelected && (
                <>
                  {/* <NotYetAvailableInCountry /> */}
                  <Flex justify="center" className="sticky bottom-0 z-10" mt={20}>
                    <Button
                      className="mb-5 rounded-[24px] sm:mx-0"
                      variant="primary"
                      loading={isDataLoading || isSubmitting}
                      fullWidth
                      size="l"
                      onClick={async () => {
                        setIsFirstSubmitAttempt(true);
                        const formErrors = await validateForm();
                        scrollToErrors(formErrors);
                        handleSubmit();
                      }}
                    >
                      {t('button.label.next')}
                    </Button>
                  </Flex>
                </>
              )}
              {defaultCountryAndSupportedNif && (
                <>
                  {values.type && (
                    <div className="rounded-[24px] bg-white p-5">
                      <Text className="pb-5 text-xl font-semibold text-black-magic">
                        {getTitleForCountry(values.country, t)}
                      </Text>
                      <FormikInput
                        ref={nifInputRef}
                        className="w-full"
                        inputMode="numeric"
                        component={IMaskInput}
                        unmask
                        mask="000 000 000"
                        customClassNames={{
                          input: 'focus:border-moroccan-blue',
                        }}
                        name="NIF"
                        handleOnAccept={(value) => {
                          if (errors.NIF) {
                            setFieldError('NIF', '');
                          }
                          setFieldValue('NIF', value);
                        }}
                        label={getLabelForCountry(values.country, t)}
                        placeholder={getPlaceholderForCountry(values.country, t) ?? ''}
                        error={errors.NIF ? t(errors.NIF) : null}
                      />
                    </div>
                  )}
                  <Flex justify="center" className="sticky bottom-0 z-10" mt={20}>
                    <Button
                      disabled={isDataLoading || isSubmitting}
                      className="mb-5 rounded-[24px] sm:mx-0"
                      loading={isDataLoading || isSubmitting}
                      onClick={async () => {
                        setIsFirstSubmitAttempt(true);
                        const formErrors = await validateForm();
                        scrollToErrors(formErrors);
                        handleSubmit();
                      }}
                      size="l"
                      fullWidth
                      variant="primary"
                    >
                      {t('button.label.next')}
                    </Button>
                  </Flex>
                </>
              )}

              {selfEmplModalOpened && (
                <SelfEmployedModal
                  selfEmplModalOpened={selfEmplModalOpened}
                  closeSelfEmplModal={closeSelfEmplModal}
                  subtypesList={subtypesData?.tenantSubtypeCategories || []}
                />
              )}
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Step1Form;
