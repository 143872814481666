import * as Yup from 'yup';
import { Tenant_Type } from '@/graphql-types';
import { isNIF, isNIPC, nifPattern, validateNifControlDigit } from '@/utils/nifNipc';
import { TFunction } from 'i18next';
import { ONBOARDING_COUNTRIES } from '@/utils';

export const availableCountrySchema = (t: TFunction) =>
  Yup.object().shape({
    country: Yup.string().required(t('validation.required')),
    type: Yup.string().required(t('validation.businessTypeRequired')),
    NIF: Yup.string().when(['country', 'type'], {
      is: (country: string, type: Tenant_Type) =>
        country === ONBOARDING_COUNTRIES.PT && type === Tenant_Type.Individual,
      then: () =>
        Yup.string()
          .required(t('validation.nifRequired'))
          .matches(nifPattern, t('validation.invalidNifFormat'))
          .test('nif-control-digit', t('validation.invalidNif'), validateNifControlDigit)
          .test('isNif', t('validation.NifMustStartWith'), isNIF),
      otherwise: () =>
        Yup.string().when(['country', 'type'], {
          is: (country: string, type: Tenant_Type) =>
            country === ONBOARDING_COUNTRIES.PT && type === Tenant_Type.Company,
          then: () =>
            Yup.string()
              .required(t('validation.nipcRequired'))
              .matches(nifPattern, t('validation.invalidNipcFormat'))
              .test('nif-control-digit', t('validation.invalidNipc'), validateNifControlDigit)
              .test('isNIPC', t('validation.NipcMustStartWith'), isNIPC),
          otherwise: () => Yup.string().required(t('validation.required')),
        }),
    }),
  });

export const waitlistedCountrySchema = (t: TFunction) =>
  Yup.object().shape({
    country: Yup.string().required(t('validation.required')),
    type: Yup.string().required(t('validation.businessTypeRequired')),
  });
