import { useFormikContext } from 'formik';
import { Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import CustomModal from '@/components/CustomModal';
import { TenantSubtypeCategory } from '@/graphql-types';
import { FormStep1Values } from '../../types';
import TenantSubtypesList from './TenantSubtypesList';
import { useTranslation } from 'react-i18next';

type SelfEmployedModalProps = {
  selfEmplModalOpened: boolean;
  closeSelfEmplModal: () => void;
  subtypesList: TenantSubtypeCategory[];
};

const SelfEmployedModal = ({ selfEmplModalOpened, closeSelfEmplModal, subtypesList }: SelfEmployedModalProps) => {
  const { t } = useTranslation();
  const isSmScreen = useMediaQuery('(max-width: 575px)');
  const { setFieldValue, values } = useFormikContext<FormStep1Values>();

  return (
    <CustomModal
      isOpened={selfEmplModalOpened}
      handleClose={() => {
        if (!values.subtype) {
          setFieldValue('type', '');
        }

        closeSelfEmplModal();
      }}
      title={
        <Text className="text-center text-[22px] font-bold">{t('page.onboarding.step1.activityModal.title')}</Text>
      }
      size={480}
      padding="lg"
      styles={{
        content: {
          minHeight: '70%',
        },
      }}
      fullScreen={isSmScreen}
    >
      <TenantSubtypesList subTypesList={subtypesList || []} closeSelfEmplModal={closeSelfEmplModal} />
    </CustomModal>
  );
};

export default SelfEmployedModal;
